import { Component, inject, input } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ConfigurationService } from '../../services/configuration.service';
import { InformationBanner } from '../../types/configuration.types';
import { InformationBannerComponent } from '../cms-elements-folder/information-banner/information-banner.component';

@Component({
	selector: 'cramo-page',
	standalone: true,
	imports: [InformationBannerComponent],
	templateUrl: './page.component.html',
	styleUrl: './page.component.scss',
})
export class PageComponent {
	private configurationService = inject(ConfigurationService);
	private titleService = inject(Title);

	public informationBanners: InformationBanner[];

	public title = input<string>();
	private title$ = toObservable(this.title);

	constructor() {
		this.informationBanners = this.configurationService.configurations.informationBannerList;

		this.title$.pipe(takeUntilDestroyed()).subscribe((title) => {
			this.titleService.setTitle(title ?? 'Cramo');
		});
	}
}
